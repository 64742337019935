:root {
    --color-primary: #5c312f;
    --color-secondary: #5c312f;
    --color-bg: #edf3ff;
    --color-font-white: #ffffff;
    /* --font-primary: 'Helvetica Neue', sans-serif; */
    --font-primary: "Poppins", sans-serif;
    --font-secondary: "Open Sans", sans-serif;
}


/* *{
    border: 1px solid red;
} */


/* General */

img {
    border-radius: 10px;
}

body {
    overflow-x: hidden;
    font-family: var(--font-primary);
}

section {
    padding-top: 40px;
    padding-bottom: 40px;
}

a {
    color: var(--color-primary);
    text-decoration: none;
}

a:hover {
    color: var(--color-secondary);
    text-decoration: none;
}


/* p {
    font-size: 16px;
    opacity: 1;
} */

.h1-tag h1 {
    color: var(--color-primary);
    font-size: 32px;
    font-weight: bold;
}

h2 {
    color: var(--color-primary);
    font-size: 32px;
    font-weight: bold;
}

h2 span {
    color: var(--color-secondary);
}

h3 span {
    font-weight: 100;
}


/* h2::before {
    content: url(../images/bricks-icon.svg);
    position: relative;
    z-index: 100000;
    top: 10px;
    padding-right: 10px;
} */

h2.tq::before,
.pri-policy h2::before {
    all: unset;
}

h3 {
    font-size: 20px;
    font-weight: bold;
}

h3.sub {
    margin-top: 15px;
    margin-bottom: 50px;
    font-size: 16px;
    font-weight: normal;
}

.team h4 {
    color: #000 !important;
}

.content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: justify;
    padding: 50px;
    column-gap: 20px;
}

.content-align {
    color: #606060;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 25px 30px 30px 25px;
}

.link-primary {
    font-size: 16px;
    font-weight: bold;
    color: var(--color-primary);
}

.link-primary:hover {
    color: var(--color-secondary);
}

.section-bg {
    background-color: #f8f8f8;
}

.bg-clients {
    padding: unset;
    background-image: url(../images/works.jpg);
    background-position: middle center;
    height: 350px;
}

.entry {
    padding-top: 80px;
}

.cmt-1 {
    margin-top: -52px;
}

.pb-unset {
    padding-bottom: unset;
}


/* .btn-primary {
    margin-top: 25px;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    border-radius: 7px;
    background-color: #0064B1;
    border-color: #0064B1;
    color: #fff;
} */

.btn-outline-light {
    font-size: 16px;
    font-weight: 700;
    padding: 5px 20px;
    text-transform: capitalize;
    border-radius: 7px;
}

.btn-outline-light:hover {
    background-color: var(--color-primary);
    color: #fff;
    border: none;
}

.btn-outline-dark {
    font-size: 16px;
    font-weight: 700;
    padding: 5px 20px;
    text-transform: capitalize;
    border-radius: 7px;
    color: #5c312f;
    border: 1px solid #5c312f;
}

.btn-outline-dark:hover {
    background-color: var(--color-primary);
    color: #fff;
    border: 1px solid #5c312f;
}

.btn-secondary {
    font-size: 14px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: capitalize;
    border-radius: 7px;
    background-color: #ffffff;
    border-color: #ffffff;
    color: #131d3b;
}

.btn-primary:hover {
    background-color: #0064b1;
    border-color: #0064b1;
    box-shadow: 0px 10px 30px #1264b14b;
}

.mt-unset {
    margin-top: unset !important;
}

.fa-flip-horizontal {
    /* -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)"; */
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.MuiTypography-root {
    color: #000;
    font-weight: 500 !important;
}

.header {
    background-color: var(--color-primary) !important;
}

.menuicon {
    color: var(--color-primary) !important;
}


/* Header */

#header {
    position: relative;
    z-index: 9999999;
}

.MuiListItemIcon-root {
    min-width: 42px !important;
}

.MuiTypography-root {
    color: #000;
}

#header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


/* Navbar */

#navbar ul {
    justify-content: flex-end;
}

.navbar ul {
    margin-top: 20px;
    display: flex;
    list-style: none;
    align-items: center;
    padding-left: 0;
}

.navbar ul li {
    padding: 10px 0 10px 40px;
}

.navbar ul li:first-child {
    padding-left: 0;
}

.navbar ul li a {
    color: #000;
    font-size: 18px;
    opacity: 0.8;
}

.navbar ul li.active a {
    color: var(--color-secondary);
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--color-secondary);
}

.breadcrumbs {
    text-align: center;
    padding: 96px 0;
    min-height: 100%;
    background: #f0f0f0;
    background-size: cover;
}

.breadcrumbs h1 {
    font-size: 40px;
    font-weight: bold;
    color: #5c312f;
}

#breadcrumb-heading {
    font-size: 40px;
}

.breadcrumbs ul {
    padding-left: unset;
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
}

.breadcrumbs ul li a,
.breadcrumbs ul li+li {
    font-size: 14px;
    font-weight: 600;
    color: #2b2b2b;
}

.breadcrumbs ul li a:hover {
    color: var(--color-primary) !important;
}

.breadcrumbs ul li+li {
    padding-left: 10px;
}

.breadcrumbs ul li+li::before {
    padding-right: 10px;
    content: "/";
    line-height: 27px;
}

#breadCrumb img {
    opacity: 0.6;
}

#breadCrumb {
    background: black;
}

.slider h1,
.slider h2 {
    font-size: 40px;
    font-weight: 700;
}

.slider {
    background: black;
}

.slider img {
    opacity: 0.5;
}

.carousel .thumbs-wrapper {
    /* margin: 20px; */
    overflow: hidden;
    display: none;
}

.carousel .carousel-status {
    display: none;
}


/* .carousel .control-dots {
    display:none;
} */

.slider .carousel-caption p {
    font-size: #fff;
}

#service h2 {
    color: #404040;
    font-size: 30px;
    font-weight: 700;
}

.css-n2n0ek {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1199;
    right: auto;
    width: 0px !important;
}

.scroll-to-top {
    background-color: unset;
    background: #5c312f !important;
    right: 10px;
    bottom: 40px;
    position: fixed;
    z-index: 2;
    cursor: pointer;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    box-shadow: unset !important;
    border: none;
}

.slider .carousel-caption {
    top: 30%;
    text-align: left;
    color: #fff;
    border-radius: 50%;
}

#slider-heading {
    top: 30%;
    text-align: left;
    color: #fff;
    border-radius: 50%;
    font-size: 40px;
    font-weight: 700;
}

.img-logo {
    /* max-height: 500px; */
    max-width: 135px !important;
}

.slider .carousel-caption svg {
    margin-top: -1px;
}

.btn-primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.btn-primary:hover {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}

.bg-1 {
    background-image: url(../images/bg-1.svg);
    background-position: bottom right;
    background-repeat: no-repeat;
    /* padding-bottom: unset; */
}

.bg-2 {
    padding: unset;
    background-image: url(../images/contact-us.jpg);
    background-position: middle center;
    /* background-repeat: no-repeat; */
    height: 420px;
}

.bgc-2 {
    height: 420px;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgb(0 0 0 / 54%) 100%, rgba(0, 0, 0, 0.3) 100%);
}

.bgc-2 .container {
    padding-top: 60px;
    padding-bottom: 60px;
}

.touch h2 {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    color: #fff;
}

.touch p {
    font: normal normal medium 18px/27px Poppins;
    letter-spacing: 0px;
    color: #d0d0d0;
    font-size: 18px;
    text-align: center;
}

.center {
    margin: 0 auto;
}

.touch h2::before,
.ncpl h2::before {
    all: unset;
}

.benefits {
    margin-top: 25px;
    padding: 22px;
    background-color: #ffffff;
    box-shadow: 0px 10px 30px #7b8ecb29;
}

marquee {
    margin-bottom: -4px;
}

.benefits img {
    float: left;
    border-radius: 50%;
    padding: 12px;
    background-color: #f9f9fd;
}

.benefits h4 {
    margin-left: 90px;
    font-size: 18px;
    font-weight: 600;
    color: #131d3b;
    text-transform: capitalize;
    opacity: 0.8;
}

.benefits p {
    margin-left: 90px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
    color: #131d3b;
    opacity: 0.8;
}

.benefits a {
    margin-left: 90px;
    font-size: 16px;
    font-weight: 500;
    color: #1264b1;
}

.team {
    text-align: center;
    position: relative;
}

.team img.design {
    position: absolute;
    right: 80px;
}

.team img.profile {
    border-radius: 50%;
    margin-bottom: 25px;
}

.team h4 {
    font-size: 20px;
    font-weight: 600;
    color: #131d3b;
    opacity: 0.8;
}

.team p {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #131d3b;
    opacity: 0.8;
}

.des-2 {
    top: -50px;
    left: -75px;
    position: absolute;
}

#footer {
    padding-top: 54px;
    padding-bottom: 70px;
    background: #ededed;
}

.top-bar {
    padding-top: 14px;
    background-color: #2b2b2b;
    display: flex;
    justify-content: space-evenly;
}

.top-bar a {
    color: white !important;
}

.flex-center {
    display: flex;
    align-items: center;
}

#footer hr {
    background-color: #2b2b2b;
}

#footer svg {
    color: #5c312f;
    float: left;
    margin-right: 5px;
}

#footer .social ul {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    /* justify-content: center; */
}

#footer .social ul li svg {
    margin: 10px;
    font-size: 40px;
    padding: 8px;
    border-radius: 50%;
    border: 2px solid #ffcc2f;
    color: #5c312f;
}

#footer h5 {
    margin-bottom: 25px;
    font-size: 22px;
    font-weight: 600;
    color: #5c312f;
    text-transform: uppercase;
}


/* #footer h5::after {
    display: inline-block;
    width: 2rem;
    height: .2rem;
    margin-left: 8px;
    margin-bottom: 4px;
    content: '';
    background-color: #fff !important;
    border-radius: 10px;
    border: 1px solid #fff;
} */

#footer .logo {
    margin-bottom: 30px;
}

#footer p {
    font-size: 16px;
    font-weight: 400;
    color: #606060;
}

#footer .contact p {
    padding-left: 30px;
}

#footer ul {
    list-style: none;
    padding-left: unset;
}

#footer ul li a,
#footer a {
    line-height: 25px;
    font-size: 16px;
    font-weight: 600;
    color: #606060;
}

#footer h6 {
    font-size: 16px;
    font-weight: 600;
    color: #606060;
    padding-bottom: 5px;
}

#footer ul li a:hover,
#footer a:hover {
    color: var(--color-secondary);
}

.copyright p,
.copyright a {
    font-size: 16px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    color: #ffffff !important;
}

#footer .links {
    text-align: right;
}

.testimonials svg {
    bottom: 0;
    color: #000;
}

.testimonials svg:hover {
    color: #ffc942;
}

.testimonials .slick-prev,
.testimonials .slick-next {
    top: unset;
    bottom: -35px;
}

.testimonials .slick-prev {
    left: 47%;
}

.testimonials .slick-next {
    right: 47%;
}

.testimonial {
    margin: 25px;
    padding: 30px;
    background-color: #ffffff;
    box-shadow: 10px 10px 20px #7b8ecb29;
}

.testimonial .slick-slide {
    position: relative;
}

.testimonial svg {
    margin-top: -12%;
    border-radius: 50%;
    z-index: 2;
    background-color: #ffc942;
    color: #fff;
    font-size: 50px;
}

.testimonial svg:hover {
    background-color: #ffc942;
    color: #fff;
}

.testimonial p {
    font-size: 16px;
    line-height: 35px;
    color: #131d3b;
    opacity: 0.8;
}

.testimonial h4 {
    font-size: 18px;
    color: #0064b1;
    text-transform: capitalize;
}

.line {
    width: 200px;
    border: 2px solid #5c312f;
}

.contact-info {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 30px !important;
    padding: 20px;
}

.contact-info svg {
    margin: 0 10px 12px 10px;
    color: var(--color-secondary);
}

.contact-info h4 {
    font-size: 18px;
    font-weight: 600;
}

.contact-info .icon {
    margin-top: 40px;
}

.contact-info p,
.contact-info p a {
    text-align: unset;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    opacity: 0.8;
    transition: 0.3s;
}

.contact-info p a:hover {
    color: var(--color-primary);
}

.contact-info .icon:before,
.contact-info .icon:after {
    display: inline-block;
    content: "";
    border-top: 1px solid var(--color-secondary);
    width: 48px;
    transform: translateY(-0.6rem);
}

.contact-form {
    padding: 8px 40px 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 30px #53749a14;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
    padding: 0.675rem 0.75rem;
}

.contact-form .react-tel-input .form-control {
    width: 100%;
    height: 45px;
    margin-top: 35px !important;
}

.contact-form h2 {
    margin-top: 14px;
    padding-bottom: 20px;
}

.contact-form .form-control,
.contact-form button {
    margin-top: 35px;
}

.contact-form .MuiInputLabel-shrink {
    padding-left: 7px !important;
    padding-right: 7px !important;
    background-color: #fff !important;
    color: #03abee !important;
}

.contact-form .MuiButton-root {
    font-size: 14px;
    border: none;
    padding: 10px 20px !important;
    background-color: #0064b1;
    border-radius: 6px;
    color: #fff !important;
}

.contact-form .MuiButton-root:hover {
    background-color: transparent;
    color: #0064b1 !important;
    border: 1px solid #0064b1;
}

.machinery {
    margin-bottom: 60px;
}

.slick-slide .machinery img {
    display: unset !important;
}

.machinery .count h4 span {
    font-size: 3rem;
    color: #c7c9cb;
}

.machinery .count h4 {
    font-weight: 600;
    font-size: 1.1rem;
    color: #131d3b;
    opacity: 0.9;
}

.pri-policy {
    margin-top: 50px;
    margin-bottom: 50px;
}

.sitemap ul {
    list-style: none;
    padding-left: unset;
}

.sitemap ul li {
    padding-top: 5px;
    padding-bottom: 5px;
}

.sitemap ul li a {
    color: #000000;
}

.sitemap ul li a:hover {
    color: #ffcc2f;
}

.gallery .MuiTab-root {
    text-transform: none;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    opacity: 0.8;
}

.gallery .MuiTab-root.Mui-selected {
    color: #000000;
}

.gallery .MuiTabs-indicator {
    background-color: #ffcc2f;
}

.gallery p {
    opacity: 1;
}

.gallery img {
    margin-bottom: 25px;
}

.info h4 span {
    font-size: 16rem;
    color: #ffcc2f;
}

.info h4 {
    margin-top: -150px;
    margin-left: -56px;
    font-weight: 700;
    font-size: 1.3rem;
    color: #131d3b;
    opacity: 0.8;
}

.client img {
    filter: grayscale();
    opacity: 0.8;
}

.client:hover img {
    filter: unset;
    opacity: 1;
}

.menu-footer {
    background-color: #ffffffd1;
    border-top: 1px solid #ccc;
    bottom: 0;
    font-size: 13px;
    left: 0;
    position: fixed;
    text-align: center;
    width: 100%;
    height: 7%;
    z-index: 999;
}

.menu-footer a {
    color: #374957 !important;
}

.menu-footer a.active {
    color: var(--color-primary) !important;
}

.menu-footer .b-right {
    border-right: 1px solid #ccc;
}

.form-control:focus {
    border-color: #dc922740;
    box-shadow: 0 0 0 0.1rem rgb(220 146 39 / 28%);
}

section.map {
    padding-bottom: unset;
}

#about {
    padding-bottom: unset;
}

#about h2 {
    color: var(--color-font-white);
    /* margin-top: 40px; */
    /* text-align: center; */
}

.white-text {
    color: #fff !important;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: #fff;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    box-shadow: 0 0 0 0.25rem var(--color-secondary);
}

.project-single {
    padding: 15px;
}

.project-single img {
    border-radius: 8px;
}

.project-single h4 {
    position: relative;
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
}

#projects {
    /* padding-bottom: 80px; */
    align-content: center;
}

.projects .slick-dots li.slick-active button:before {
    color: var(--color-secondary);
}

.projects .slick-dots li button:before {
    font-size: 10px;
}

.projects .slick-dots li.slick-active button:before {
    font-size: 15px;
}

.mission-row {
    overflow: hidden;
    position: relative;
}

.card-mission-1,
.card-mission-2,
.card-mission-3 {
    text-align: center;
    padding: 45px 25px;
    background: #f9f9fd 0% 0% no-repeat padding-box;
    border-radius: 10px;
    transition: all 0.5s;
    height: 90%;
}

.card-mission-1:hover,
.card-mission-2:hover,
.card-mission-3:hover {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 60px #586eb529;
}

.card-mission-1 .icon-box,
.card-mission-2 .icon-box,
.card-mission-3 .icon-box {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 12px #00000029;
    border-radius: 16px;
    margin-bottom: 30px;
    transition: all 0.5s;
}

.card-mission-1:hover .icon-box,
.card-mission-2:hover .icon-box,
.card-mission-3:hover .icon-box {
    background: var(--color-primary) 0% 0% no-repeat padding-box;
    box-shadow: none;
    border-radius: 16px;
}

.mission table {
    margin: auto;
}

.or-1,
.or-2,
.or-3 {
    display: table;
}

.hover-1,
.hover-2,
.hover-3 {
    display: none;
}

.card-mission-1:hover .or-1 {
    display: none;
}

.card-mission-1:hover .hover-1 {
    display: block;
}

.card-mission-2:hover .or-2 {
    display: none;
}

.card-mission-2:hover .hover-2 {
    display: block;
}

.card-mission-3:hover .or-3 {
    display: none;
}

.card-mission-3:hover .hover-3 {
    display: block;
}

.counts h5 {
    text-align: center;
}

.counts .card-count {
    width: fit-content;
    padding: 2px 8px 2px 58px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.counts .card-count.type-1 {
    background-color: #f4e4d1;
}

.counts .card-count.type-2 {
    background-color: #e3ddd6;
}

.counts .card-count span {
    font-size: 60px;
    font-weight: 600;
    margin-right: -44px;
    color: var(--color-secondary);
}

section.counts {
    padding: unset;
}

.img-area {
    display: flex;
    position: relative;
}

.design-2 {
    width: fit-content;
    height: 150px;
    padding: 22px;
    position: relative;
    top: 30%;
    left: 15%;
    background-color: var(--color-primary);
}

.contacts {
    overflow: hidden;
    margin-top: 30px;
    margin-bottom: 30px;
}

.contacts .icon {
    float: left;
    padding: 7px;
    border-radius: 50%;
    color: #ffffff;
    width: fit-content;
    background-color: var(--color-primary);
}

.contacts a,
.contacts p {
    font-size: 16px;
    color: #000;
    opacity: 0.7;
    padding-left: 20px;
}

.contacts p {
    padding-left: 60px;
}

.contacts a:hover {
    color: var(--color-secondary);
}

section#contact {
    padding-bottom: unset;
}

.ncpl h2 {
    color: #000;
    opacity: 0.8;
    font-weight: 500;
}

.photos .slick-prev:before,
.slick-next:before {
    font-size: 28px;
    color: var(--color-secondary);
}

.photos .slick-prev {
    left: -52px;
}

.photos .slick-next {
    right: -48px;
}


/* img.bg {
    background-image: url(../images/img-bg.png), url(../images/img-bg.png);
    background-position: left top, right bottom;
    background-repeat: no-repeat, no-repeat;
    padding: 15px;
} */

section.rmc {
    background-image: url(../images/bg-4.svg);
    background-position: right bottom;
    background-repeat: no-repeat;
}


/* New Addition */

.logo {
    max-width: 250px;
    border-radius: unset !important;
}

.service-card {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
}

.card {
    max-width: 24rem;
    border: none;
}

.card-text {
    text-align: justify;
}

.prj-img {
    max-width: 385px;
    max-height: 295px;
    opacity: 1;
}

.prj-img:hover {
    opacity: 0.5;
    cursor: pointer;
    transition: 0.5s;
}

.team-img-size {
    max-width: 400px;
    max-height: 500px;
}

#projects h2 {
    color: var(--color-font-white);
}

.projects-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.projects-flex p {
    color: var(--color-font-white);
}

.bg-red {
    background: red;
}

.bg-brown {
    background: var(--color-primary);
}

.bg-lightbrown {
    background: linear-gradient(90deg, rgba(92, 49, 47, 0.8) 100%, rgba(92, 49, 47, 0.8) 100%);
}

.bg-div {
    background: #f0f0f0;
}

.relative {
    position: relative;
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.absolute-right {
    position: absolute;
    left: 60%;
    top: 10%;
    bottom: 10%;
    width: 640px;
    z-index: 9999;
}

.absolute-left {
    position: absolute;
    right: 60%;
    top: 10%;
    bottom: 10%;
    width: 640px;
    z-index: 9999;
}

.experience {
    text-align: center;
    color: white;
}

.experience h2 {
    color: var(--color-font-white) !important;
}

.experience h3 {
    font-size: 32px;
}

.gallery ul li,
.work ul li {
    overflow: hidden;
}

.gallery ul li img,
.work ul li img {
    transition: 1s;
    opacity: 1;
    background-size: contain;
    background-position: center;
}

.gallery ul li:hover img,
.work ul li:hover img {
    opacity: 1;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    transition: 1s;
    border-radius: 8px;
}

.content_img {
    position: relative;
}

.content_img div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bolder;
    color: #333333;
    opacity: 0;
    font-size: 20px;
    text-align: center;
    visibility: hidden;
    -webkit-transition: visibility 0s, opacity 0.5s linear;
    transition: visibility 0s, opacity 0.5s linear;
}

.content_img:hover {
    cursor: pointer;
}


/* .content_img:hover div {
    visibility: visible;
    opacity: 1;
} */

.mt-10 {
    margin-top: 8em;
}

.service-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-div {
    width: 40%;
}

.right-div {
    width: 40%;
    min-height: 330px;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    text-align: justify;
    justify-content: center;
    padding: 30px;
    border-radius: 8px;
    margin-left: -150px;
    z-index: 2;
}

.right-divLeft {
    width: 40%;
    min-height: 330px;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    text-align: justify;
    justify-content: center;
    padding: 30px;
    border-radius: 8px;
    margin-right: -150px;
    z-index: 2;
}

.pb-10 {
    padding-bottom: 6rem;
}

.col-aligncenter {
    float: none;
    margin: 0 auto;
}

.specialization h4 {
    color: #606060;
}

.experience-inner {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
}

.abt-right-div {
    text-align: justify;
    color: #606060;
    margin-left: 6em;
}

.abt-right-div h5 {
    font-weight: 700 !important;
}

.abt-left-div h5 {
    font-weight: 700 !important;
}

.abt-left-div {
    text-align: justify;
    color: #606060;
    margin-right: 6em;
}

.slick-dots li button:before {
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    right: 0;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: #aba9a8;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before,
.slick-next:before {
    font-family: "slick";
    font-size: 30px;
    line-height: 1;
    opacity: 0.75;
    color: #aba9a8;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.btn-primary-1 {
    background: #643638 !important;
    border: 0px;
    border-radius: 0px;
    box-shadow: none;
    color: rgb(255, 255, 255);
    cursor: pointer;
    flex: 0 0 auto;
    padding: 5px 10px;
    margin: 15px;
}

.card-body {
    min-height: 12rem;
}

.card-body-specialization {
    min-height: 16rem;
}

.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    left: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: unset !important;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}