@media only screen and (max-width: 1052px) {
    #slider-heading {
        font-size: 28px;
    }
    .top-bar {
        display: none;
    }
}

@media (min-width: 945px) and (max-width: 1200px) {
    #service img {
        height: 350px;
    }
    .left-div {
        width: 50%;
    }
    .right-div {
        width: 65%;
        min-height: 100px;
        background-color: #f0f0f0;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        padding: 30px;
        border-radius: 8px;
        margin-left: -150px;
        z-index: 2;
    }
    .right-divLeft {
        width: 65%;
        min-height: 100px;
        background-color: #f0f0f0;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        padding: 30px;
        border-radius: 8px;
        margin-right: -150px;
        z-index: 2;
    }
}

@media only screen and (max-width: 945px) {
    .slider h1,
    .slider h2 {
        font-size: 32px;
        font-weight: 300;
    }
    #footer {
        text-align: center;
    }
    #footer svg {
        color: #5c312f;
        float: unset;
        margin-right: unset;
    }
    #header .container {
        display: flex !important;
        align-content: space-between;
        flex-wrap: wrap;
        justify-content: center !important;
    }
    .left-div {
        width: 50%;
    }
    .right-div {
        width: 70%;
        min-height: 60px;
        background-color: #f0f0f0;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        padding: 30px;
        border-radius: 8px;
        margin-left: -150px;
        z-index: 2;
    }
    .right-divLeft {
        width: 70%;
        min-height: 60px;
        background-color: #f0f0f0;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        padding: 30px;
        border-radius: 8px;
        margin-right: -150px;
        z-index: 2;
    }
}

@media only screen and (max-width: 768px) {
    /* h2::before {
        top: 5px;
        content: url(../images/bricks-icon-mobile.svg);
    } */
    #header .container {
        display: flex;
        align-items: center;
        justify-content: space-between !important;
    }
    .carousel .carousel-status {
        display: none;
    }
    .carousel .control-dots {
        display: none;
    }
    .top-bar {
        display: none;
    }
    .link-primary {
        font-size: 16px;
        font-weight: 500;
    }
    .navbar {
        justify-content: space-around !important;
    }
    .MuiListItemIcon-root {
        min-width: 42px !important;
    }
    .MuiDrawer-paper img {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .slider .carousel-caption {
        top: 12%;
    }
    .slider .carousel-caption h1 {
        font-size: 0.8rem;
    }
    #slider-heading {
        font-size: 0.8rem;
    }
    .slider .carousel-caption p {
        margin-top: 8px;
        font-size: 9px;
        font-weight: normal;
    }
    .slider h1,
    .slider h2 {
        font-size: 16px;
        font-weight: 400;
    }
    .slider .carousel-indicators {
        top: 15%;
    }
    .slider .carousel-indicators [data-bs-target] {
        width: 7px;
        height: 7px;
    }
    .slider .carousel-indicators .active {
        width: 12px !important;
        height: 12px !important;
    }
    .entry {
        padding-top: 30px;
    }
    .info h4 {
        margin-top: -56px;
        margin-left: 3px;
        font-weight: 600;
        font-size: 1.1rem;
    }
    .info h4 span {
        font-size: 6rem;
    }
    section h2 {
        margin-bottom: 9px;
        font-size: 20px;
    }
    section p {
        font-size: 15px;
        line-height: 28px;
    }
    .btn-primary {
        margin-top: unset;
    }
    .benefits img {
        padding: 6px;
    }
    h5 {
        font-size: 15px;
    }
    .benefits h4,
    .benefits p,
    .benefits a {
        margin-left: 66px;
    }
    .benefits h4 {
        font-size: 16px;
    }
    .benefits p {
        line-height: 26px;
        font-size: 14px;
        margin-bottom: 2px;
    }
    .benefits a {
        font-size: 14px;
    }
    section h3 {
        margin-bottom: 20px;
        font-size: 20px !important;
        line-height: 30px;
    }
    .touch h2 {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
    }
    .bgc-2 .container {
        padding-top: 42px;
        padding-bottom: 42px;
    }
    .testimonial {
        padding: 22px;
    }
    .testimonial p {
        font-size: 14px;
        line-height: 24px;
    }
    .testimonial h4 {
        font-size: 14px;
    }
    .testimonials .slick-next {
        right: 40%;
    }
    .testimonials .slick-prev {
        left: 40%;
    }
    .mlogo {
        margin-left: -25px;
    }
    #footer {
        padding-top: 28px;
        text-align: center;
        padding-bottom: 60px;
    }
    #footer svg {
        color: #5c312f;
        float: unset;
        margin-right: unset;
    }
    .copyright,
    #footer .links {
        text-align: center;
    }
    .breadcrumbs {
        padding: unset;
        padding-bottom: 14px;
        padding-top: 42px;
    }
    .breadcrumbs h1 {
        font: normal normal 500 22px/0px Poppins;
    }
    .breadcrumbs ul li a,
    .breadcrumbs ul li+li::before,
    .breadcrumbs ul li+li {
        font: normal normal 500 12px/36px Poppins;
    }
    .des-2 {
        width: 22%;
        top: -26px;
        left: 0px;
    }
    .css-jn8x8h {
        left: unset !important;
        -webkit-transform: translate(0%, -50%) !important;
        -moz-transform: translate(0%, -50%) !important;
        -ms-transform: translate(0%, -50%) !important;
        transform: translate(0%, -50%) !important;
    }
    .contact-form {
        padding: 18px;
        padding-top: 26px;
    }
    .contact-info .icon.mt-unset {
        margin-top: 40px !important;
    }
    section.counts {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .counts .card-count {
        padding: 4px 22px 2px 37px;
        margin-left: unset;
        margin-right: unset;
        margin-bottom: 20px;
    }
    .counts .card-count span {
        font-size: 44px;
    }
    .counts h5 {
        text-align: left;
        font-size: 16px;
    }
    .counts .col-6 {
        margin-bottom: 30px;
    }
    .design-2 {
        top: 16%;
        left: 10%;
    }
    #footer h5 {
        margin-top: 30px;
    }
    #footer {
        background: unset;
        background-color: #ededed;
    }
    .bottom {
        margin-bottom: 57px;
    }
    .slick-slider.photos .slick-prev,
    .slick-slider.photos .slick-next {
        top: unset;
        bottom: -25%;
    }
    section.ncpl {
        margin-top: 3rem;
    }
    .slick-slider.photos .slick-prev {
        left: 38%;
    }
    .slick-slider.photos .slick-next {
        right: 38%;
    }
    .contact-info {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ncpl h2 {
        text-align: center;
    }
    .service-card {
        flex-wrap: wrap;
    }
    .ncpl a {
        margin-top: 20px;
    }
    .service-container {
        flex-direction: column;
    }
    .mt-5 {
        margin-top: 0.5rem !important;
    }
    .mt-10 {
        margin-top: 3em;
    }
    .left-div {
        width: 100%;
    }
    .right-div {
        width: 90%;
        margin: 0;
        margin-top: -50px;
    }
    .right-divLeft {
        width: 90%;
        margin: 0;
        margin-bottom: -50px;
    }
    #breadCrumb h1 {
        font-size: 22px;
    }
    #breadcrumb-heading {
        font-size: 22px;
    }
    .pb-10 {
        padding-bottom: 3rem;
    }
    #service h2 {
        color: #404040;
        font-size: 25px;
        font-weight: 700;
    }
    .abt-right-div {
        margin-top: 10px;
        text-align: center;
        color: #606060;
        margin-left: unset;
    }
    .experience {
        flex-direction: column;
    }
    .abt-left-div {
        margin-top: 10px;
        text-align: center;
        color: #606060;
        margin-right: unset;
    }
    .card-body {
        min-height: 10rem;
    }
    /* .card-body-specialization {
        min-height: unset;
    } */
    .content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        padding: 26px;
        column-gap: 20px;
    }
    .whatsapp-icon {
        margin-top: 10px;
    }
    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 65px;
        right: 10px;
        font-size: 22px;
    }
    .scroll-to-top {
        background-color: unset;
        background: #5c312f !important;
        right: 10px;
        bottom: 65px !important;
        position: fixed;
        z-index: 2;
        cursor: pointer;
        border-radius: 7px;
        width: 40px;
        height: 40px;
        box-shadow: unset !important;
        border: none;
    }
    /* .project-single {
        margin: 5px;
    } */
}